$('document').ready(function(){


    //MAIN MENU ACTIVE SECTION
    let homeLink = document.querySelector('.menuHome');
    let aboutLink = document.querySelector('.menuAbout');
    let contestLink = document.querySelector('.menuContest');
    // var urlPathName = window.location.pathname;
    // var urlHomePathName = /*[[#{url.path.accueil}]]*/'';
    // var urlAboutPathName = /*[[#{url.path.about}]]*/'';
    //var urlContestPathName = /*[[#{url.path.contest}]]*/'';
    if (urlPathName === urlHomePathName ) {
        homeLink.classList.add('active-link');
    } else if (urlPathName === urlAboutPathName) {
        aboutLink.classList.add('active-link');
    } else if (urlPathName === urlContestPathName) {
        contestLink.classList.add('active-link');
    }

    //STANDALONE
    document.querySelectorAll('.accordion--standalone').forEach((acc) => {
        acc.addEventListener('click', function () {
            this.classList.toggle('active-faq');
            let panel = this.nextElementSibling;
            panel.style.display = panel.style.display === 'block' ? 'none' : 'block';
        });
    });

});